@import '../../index.scss';

.thumbnail{
	text-decoration: none;
	position: relative;
	background: $mainColor;
	color: white;
	display: flex;
	aspect-ratio: 1 / 1;
	flex-direction: column;
	justify-content: flex-end;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
		border-radius: $border-radius;
	}
	
	background-size: cover;
	width: 340px;
	border-radius: $border-radius;

	& > * {
		z-index: 1;
	}

	h3 {
		font-weight: 400;
		margin: 0;
		padding: 10px;
	}

	@media screen and (max-width: 900px) {
		width: 100%;
		max-height: 200px;
	}
}