@import '../../index.scss';

.stars_container{
	display: flex;
}

.star{
	padding: 3px;
	@media screen and (max-width: 468px) {
		height: 20px;
	}
}

