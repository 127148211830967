@import '../../index.scss';

.footer{
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	color: white;
	height: 150px;
	& > p {
		margin: 0;
	}
}