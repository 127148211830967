@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$mainColor: #FF6060;
$lightBG: #F6F6F6;
$border-radius: 10px;

main{
	min-height: calc(100vh - 280px);
	display: flex;
	gap: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 30px;
	@media screen and (max-width: 768px) {
		min-height: calc(100vh - 260px);

	}
}

.wrapping{
	width: min(90%, 1920px);
	margin: 0 auto;
}