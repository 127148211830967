@import '../../index.scss';

.caroussel{
	height: 415px;

	&_title-type {
		height: 220px;
	}

	@media screen and (max-width: 1024px) {
		height: 300px;
		&_title-type {
			height: 110px;
		}
	}
	
	@media screen and (max-width: 468px) {
		height: 250px;
		&_title-type {
			height: 110px;
		}
	}

	width: 100%;
	position: relative;

	&_arrow{
		cursor: pointer;
		position: absolute;
		height: calc(100% - 20px);
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 10px;
		font-size: 40px;
		color: white;
		&_right{
			right: 0;
		}
	}

	&_counter{
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 22px;
		bottom: 20px;
		color: white;
		@media screen and (max-width: 468px) {
			display:none;
		}
	}

	&_title{
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 1;
		@media screen and (max-width: 1024px) {
			justify-content: flex-start;
			padding: 0 20px;
		}

		> h1 {
			font-weight: 500;
			font-size: 34px;
			@media screen and (max-width: 1024px) {
				font-size: 22px;
				text-align: left;
			}

			@media screen and (max-width: 468px) {
				font-weight: 400;
			}
		}
	}

	img{
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 20px;
	}
}
