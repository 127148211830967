@import '../../index.scss';

.navbar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100px;
	@media screen and (max-width: 468px) {
		height: 80px;
	}

	& > img {
		height: 50px;
		@media screen and (max-width: 468px) {
			height: 35px;
		}
	}

	& > ul {
		display: flex;
		gap: 20px;
		list-style-type: none;
		margin: 0;
		padding: 0;
		& > li > a {
			color: $mainColor;
			text-decoration: none;
			font-weight: 400;
			font-size: 20px;
			@media screen and (max-width: 468px) {
				font-size: 16px;
			}

			&:hover{
				text-decoration: underline;
				text-underline-position: under;
				text-decoration-thickness: 1.5px;
			}
		}
	}
}