@import '../index.scss';

.tag_list {
	display: flex;
	gap: 8px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	flex-wrap: wrap;
}

.house_infos_section{
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}
	> div {
		min-height: 130px;
		color:$mainColor;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		> h1 {
			font-weight: 500;
			margin: 0;
			@media screen and (max-width: 1024px) {
				font-size: 26px;
			}
		}
		> p {
			margin: 2px;
			font-weight: 500;

		}
		> ul {
			padding-top: 25px;
		}
	}

	> aside {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media screen and (max-width: 1024px) {
			flex-direction: row-reverse;
			align-items: center;
		}
	}
}

.house_owner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 4px;
		height: 60px;
		> p {
			color: #FF6060;
			margin: 0;
			text-align: end;
			font-weight: 500;
		}
	}
}

#profil_picture{
border-radius: 100%;
width: 80px;
height: 80px;
@media screen and (max-width: 468px) {
	width: 60px;
	height: 60px;
}
}

.house_description_section{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	gap: 40px;
	> div {
		max-width: calc(50% - 5%);
		
		@media screen and (max-width: 1024px) {
			max-width: 100%;
		}
	}
}

main{
	min-height: calc(100vh - 280px);
	display: flex;
	gap: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 30px;
	@media screen and (max-width: 768px) {
		min-height: calc(100vh - 260px);

	}
}

.home_grid{
	max-width: min(calc(90% - 80px), calc(1920px - 80px));
	display: flex;
	background-color: $lightBG;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 40px;
	padding: 40px;
	border-radius: 20px;

	@media screen and (max-width: 900px) {
		padding: 0;
		max-width: 100%;
		background-color: white;
	}
}

.not_found{
	color: $mainColor;
	justify-content: space-around;

	> div {
		
		display: flex;
		flex-direction: column;
		align-items: center;
		> h1 {
			@media screen and (max-width: 768px) {
					font-size: 100px;
			}
			font-size: 200px;
			margin: 0;
			font-weight: bold;
		}
		> p {
			@media screen and (max-width: 768px) {
				font-size: 16px;
				max-width: 80%;
				text-align: center;
			}
			font-size: 26px;
			font-weight: 500;
		}
	}
	> a {
		color: $mainColor;
		text-decoration: none;
	}
}