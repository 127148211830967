@import '../../index.scss';

.dropdown{
	max-width: 90%;
	width: min(1024px, 100%);
	font-size: 1em;
	&_head{
		background-color: $mainColor;
		color: white;
		padding: 10px 15px;
		border-radius: $border-radius;
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 1;
		cursor: pointer;
	}

	&_icon{
		font-size: 24px;
	}

	&_content{
		$pan: 25px;
		$padding: 20px;
		background-color: $lightBG;
		padding: $padding;
		padding-top: $pan + $padding;
		margin-top: -$pan;
		color: $mainColor;
		border-radius: $border-radius;
	}
}
